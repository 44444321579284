/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import EarlyAccess from "../components/earlyAccess"
import { createGlobalStyle } from "styled-components"
import * as fonts from "../fonts"

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'KiriFont';
    font-style: normal;
    font-weight: normal;    
    src: local('KiriFont'), url('${fonts.KiriFont}') format("woff2"),
       url('${fonts.KiriFont2}') format("woff");
  }
`

const App = props => (
  <Layout>
    <GlobalStyle />
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <EarlyAccess />
  </Layout>
)

export default App
