/** @jsx jsx */
import { jsx } from "theme-ui"
// eslint-disable-next-line
import React from "react"
import Button from "./button"
import { Flex, Box } from "rebass"
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Card from "./democardNormal"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Label, Input, Select, Textarea, Radio, Checkbox } from "@rebass/forms"
import { Container } from "./layoutComponents"

const EarlyAccess = () => (
  <Container>
    <Flex
      sx={{
        flexDirection: "column",
        m: "auto",
        mt: 83.2,
        py: 4,
      }}
    >
      <Flex
        sx={{
          flexWrap: "wrap",
          m: "auto",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            flex: "1 1 420px",
            m: "auto",
            maxWidth: "1072px",
          }}
        >
          <h1
            sx={{
              m: 0,
              p: 3,
            }}
          >
            Building a Better Way to Adopt Dogs
          </h1>
          <p
            sx={{
              m: 0,
              p: 3,
            }}
          >
            If you love dogs, <b>we're looking for your feedback.</b> Our goals
            are to reduce the number of dogs entering shelters and see more of
            them find the right homes.{" "}
            <b>Sign up for our early access waitlist and newsletter</b> and help
            us build a better way to adopt.
          </p>
          <Flex
            sx={{
              //m: 4,
              p: 2,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <Box
              as="form"
              onSubmit={e => e.preventDefault()}
              sx={{
                m: 1,
                flex: "2 2 100px",
              }}
            >
              {/* <Label htmlFor="firstname">First Name</Label> */}
              <Input
                sx={{
                  m: 0,
                  p: 2,
                  borderRadius: 4,
                  borderWidth: 2,
                  variant: "buttons.gray",
                  "&:hover": {
                    color: "primary",
                    bg: "transparent",
                    borderStyle: "solid",
                    borderColor: "primary",
                    borderWidth: 2,
                    "&:hover": {
                      bg: "rgba(48, 53, 54, 0.05)",
                      borderColor: "transparent",
                      color: "primaryDark",
                    },
                  },
                }}
                id="firstname"
                name="firstname"
                defaultValue="Jane"
              />
            </Box>
            <Box
              as="form"
              onSubmit={e => e.preventDefault()}
              sx={{
                m: 1,
                flex: "2 2 100px",
              }}
            >
              {/* <Label htmlFor="lastname">Last Name</Label> */}
              <Input
                sx={{
                  m: 0,
                  p: 2,
                  borderRadius: 4,
                  borderWidth: 2,
                  variant: "buttons.gray",
                  "&:hover": {
                    color: "primary",
                    bg: "transparent",
                    borderStyle: "solid",
                    borderColor: "primary",
                    borderWidth: 2,
                    "&:hover": {
                      bg: "rgba(48, 53, 54, 0.05)",
                      borderColor: "transparent",
                      color: "primaryDark",
                    },
                  },
                }}
                id="lastname"
                name="lastname"
                defaultValue="Doe"
              />
            </Box>
            <Box
              as="form"
              onSubmit={e => e.preventDefault()}
              sx={{
                m: 1,
                flex: "2 2 200px",
              }}
            >
              {/* <Label htmlFor="email">Email</Label> */}
              <Input
                sx={{
                  m: 0,
                  p: 2,
                  borderRadius: 4,
                  borderWidth: 2,
                  variant: "buttons.gray",
                  "&:hover": {
                    color: "primary",
                    bg: "transparent",
                    borderStyle: "solid",
                    borderColor: "primary",
                    borderWidth: 2,
                    "&:hover": {
                      bg: "rgba(48, 53, 54, 0.05)",
                      borderColor: "transparent",
                      color: "primaryDark",
                    },
                  },
                }}
                id="email"
                name="email"
                defaultValue="jane.doe@domain.com"
              />
            </Box>
            <Button
              sx={{
                variant: "buttons.primary",
                m: 1,
                px: 3,
                //maxWidth: "552px",
                flex: "1 1 100px",
              }}
            >
              Get Early Access
            </Button>
          </Flex>
          <Flex
            sx={{
              justifyContent: "center",
              justifySelf: "center",
              flexWrap: "wrap",
              flexDirection: "row",
              p: 2,
              m: 0,
            }}
          >
            <Label
              p={2}
              sx={{
                flex: "1 1 320px",
              }}
            >
              <Checkbox id="newsletter" name="newsletter" defaultChecked />
              Sign Up for Newsletter
            </Label>
          </Flex>
          <Box
            sx={{
              justifyItems: "left",
              alignItems: "left",
            }}
          >
            <p
              sx={{
                p: 3,
                m: 0,
              }}
            >
              Interested in using
              <span
                sx={{
                  fontFamily: "KiriFont",
                  letterSpacing: "-1.5px",
                }}
              >
                {" "}
                AllTails{" "}
              </span>
              as an organization?{" "}
              <Link to="./listing">
                <b>Click here!</b>
              </Link>
            </p>
          </Box>
        </Box>
        <Box
          sx={{
            opacity: 0.9,
          }}
        >
          <Flex
            sx={{
              justifyContent: "center",
              justifySelf: "center",
              flexWrap: "wrap",
              flexDirection: "column",
              p: 4,
              m: 0,
            }}
          >
            <Card
              sx={{
                scale: 2,
              }}
            />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  </Container>
)

export default EarlyAccess
